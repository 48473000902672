/* haze and logo styles */
.haze {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 10%, rgba(0,0,0,0.9) 90%);
  animation: haze 3s ease-in-out forwards;
  z-index: 2; /* logoよりも後ろに配置 */
  opacity: 1;
}

.container.loaded .logo {
  opacity: 1;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 3; /* 最前面に配置 */
}

/* Menu styles */
.menu-container {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.menu-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 0; /* ボタンの角を四角くする */
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: -150px;
  width: 150px;
  height: 300px;
  background-color: rgba(0, 0, 0, 1); /* 不透過にする */
  transition: left 0.3s ease;
}

.menu-overlay.open {
  left: 0;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 10px;
  cursor: pointer;
  color: white;
}

/* Link styles */
.menu-link {
  color: white;
  text-decoration: none;
}

.menu-link:hover {
  text-decoration: underline;
}
