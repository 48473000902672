body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .login-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .login-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .login-container p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .login-form .input-group {
    margin-bottom: 15px;
  }
  
  .login-form .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .login-form .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .forgot-link {
    margin-top: 15px;
    text-align: center;
  }
  
  .forgot-link a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .forgot-link a:hover {
    text-decoration: underline;
  }
  