/* Schedule.css */
.calendar-container {
    margin: 50px;
    padding: 20px;
    background: #f7f9fc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rbc-calendar {
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
}

.rbc-toolbar {
    background: #4a90e2;
    color: #ffffff;
    border-radius: 10px 10px 0 0;
}

.rbc-toolbar button {
    color: #ffffff;
}

.rbc-month-view .rbc-event {
    font-size: 12px;
    height: auto; /* イベントの高さを自動に設定する */
    margin-bottom: 4px; /* イベントの間隔を設定する */
    line-height: 1; /* イベントの行の高さを調整 */
  }

.rbc-row-content {
    height: auto !important; /* イベントが重なっても全て表示されるようにする */
    overflow-y: visible !important; /* スクロールしないようにする */
  }

.rbc-event {
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 12px;
    height: auto;
    margin-bottom: 4px;
}

.rbc-selected {
    background-color: #4a90e2 !important;
}

.rbc-today {
    background: #eaf1fb;
}

.rbc-day-bg:nth-of-type(7n+1) { /* 日曜日 */
    background-color: #ffe6e6;
}

.rbc-day-bg:nth-of-type(7n) { /* 土曜日 */
    background-color: #e6f7ff;
}

.rbc-off-range-bg, 
.rbc-off-range { /* 前月、来月の日付 */
    background-color: #f0f0f0!important;
}

.rbc-off-range .rbc-day-bg { /* 前月、来月の日付の背景色を全面変更 */
    background-color: #f0f0f0;
}

.custom-event-wrapper {
    font-size: 10px; /* イベントのフォントサイズをさらに小さくする */
    height: auto; /* イベントの高さを自動に設定する */
    margin-bottom: 4px; /* イベントの間隔を設定する */
}

.custom-event {
    display: flex;
    justify-content: space-between;
}

.capacity {
    margin-left: 8px; /* CAPACITYをタイトルからの間隔を調整 */
}

.event-detail {
    position: fixed;
    bottom: -100%; /* 初期状態では下に隠れている */
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-top: 1px solid #ddd;
    transition: bottom 0.3s ease-in-out; /* bottom プロパティの変化に対してアニメーションを設定 */
  }

  .event-detail.open {
    bottom: 0; /* イベント詳細が表示されたときに下からぬるっと出てくる */
  }
  
  .slide-up {
    bottom: 0;
  }
  
  .close-btn {
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .close-btn:hover {
    background-color: #357bd8;
  }