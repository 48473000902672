/* About.css */

.about-container {
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .about-heading {
    color: blue;
  }
  
  .about-text {
    font-size: 16px;
  }
  