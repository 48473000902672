.menu-container {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.menu-button {
  cursor: pointer;
  background-color: rgba(147, 187, 240);
  color: white;
  padding: 10px 20px;
  border-radius: 0;
  font-size: 16px; /* 文字サイズを指定 */
  line-height: 1.5; /* 行間を調整 */
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: -150px;
  width: 150px;
  height: 300px;
  background-color: rgba(147, 187, 240);
  transition: left 0.3s ease;
}

.menu-overlay.open {
  left: 0;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 10px 20px;
  cursor: pointer;
  color: white;
  font-size: 16px; /* 文字サイズを指定 */
  line-height: 1.5; /* 行間を調整 */
}

.menu-link {
  color: white;
  text-decoration: none;
}

.menu-link:hover {
  text-decoration: underline;
}
